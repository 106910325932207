import { FC, useCallback, useMemo } from 'react';
import { Button, ColorProps, Flex, Heading, Text } from '@chakra-ui/react';
import { Comment, Question, Submission } from '@/types/pages/questions';
import { useUser } from '@/hooks';
import { Difficulty, Routes } from '@/constants';
import { AchievementItem as AchievementItemType } from '@/types';
import { getAchievements } from '@/utils';
import { AppLink, Loader } from '@/components/common';
import { ProgressRing } from './ProgressBar';

const DEFAULT_PROGRESS_BAR_VALUE = 1;

interface ProgressSectionsProps {
  questions: Question[];
  comments: Comment[];
  submissions: Submission[];
  isDataLoading: boolean;
}

const getDifficultyDotColor = (difficulty: Difficulty | null): ColorProps['color'] => {
  switch (difficulty) {
    case Difficulty.Easy:
      return 'green.300';
    case Difficulty.Medium:
      return 'orange.200';
    case Difficulty.Hard:
      return 'red.200';
    default:
      return;
  }
};

const AchievementItem: FC<AchievementItemType> = (props) => {
  const { difficulty, solvedAmount, textBasedQuestionsIds, codeQuestionsIds } = props;
  const questionsAmount = textBasedQuestionsIds.length + codeQuestionsIds.length;

  return (
    <Flex w="100%" justifyContent="space-between" gap="12px">
      <Flex alignItems="center" gap="6px">
        <Text w="8px" h="8px" mt="2px" backgroundColor={getDifficultyDotColor(difficulty)} borderRadius="50%" />

        <Text fontSize="12px" lineHeight="16px" fontWeight={500} color="black.200">
          {difficulty}
        </Text>
      </Flex>
      <Text alignSelf="flex-end" fontSize="12px" lineHeight="16px" fontWeight={500} color="black.200">
        {solvedAmount}/{questionsAmount}
      </Text>
    </Flex>
  );
};

const ProgressSection: FC<ProgressSectionsProps> = (props) => {
  const { questions, comments, submissions, isDataLoading } = props;
  const { user } = useUser();

  const achievementsForDisplay = useMemo(
    (): AchievementItemType[] => getAchievements(user, questions, comments, submissions),
    [user, questions, comments, submissions],
  );

  const totalSolvedQuestions = achievementsForDisplay.reduce((acc, item) => acc + item.solvedAmount, 0);
  const totalPercentageSolvedQuestions = useMemo(
    () => (isDataLoading ? 0 : Math.ceil((totalSolvedQuestions / questions.length) * 100)),
    [totalSolvedQuestions, questions.length, isDataLoading],
  );

  const getPercentOfSolvedQuestions = useCallback(
    (item: AchievementItemType): number => {
      if (!item || !user || !item.solvedAmount) {
        return DEFAULT_PROGRESS_BAR_VALUE;
      }
      const amountQuestions = item.codeQuestionsIds.length + item.textBasedQuestionsIds.length;
      return Math.ceil((item.solvedAmount / amountQuestions) * 100);
    },
    [user],
  );

  return (
    <Flex width="100%" flexDir="column">
      <Heading as="h3" alignSelf="baseline" variant="secondary" mb="18px">
        Your Progress
      </Heading>

      <Flex w="100%" gap="30px" alignItems="center">
        <ProgressRing
          easy={getPercentOfSolvedQuestions(achievementsForDisplay[0])}
          medium={getPercentOfSolvedQuestions(achievementsForDisplay[1])}
          hard={getPercentOfSolvedQuestions(achievementsForDisplay[2])}
          percentage={totalPercentageSolvedQuestions}
        />

        <>
          {!isDataLoading ? (
            <Flex flexDir="column" justifyContent="flex-start" my="8px">
              {achievementsForDisplay.map((item, index) => (
                <AchievementItem key={index} {...item} />
              ))}
            </Flex>
          ) : (
            <Loader width="90px" alignSelf="center" margin="0" />
          )}
        </>
      </Flex>

      {user && (
        <Button
          as={AppLink}
          href={Routes.ProfilePerformance}
          variant="wrapper"
          mt="20px"
          bg="gray.200"
          fontFamily="body"
          color="black.500"
          _hover={{ textDecoration: 'none', bg: 'gray.300' }}
        >
          Refer Friends To Unlock More Questions
        </Button>
      )}
    </Flex>
  );
};

export default ProgressSection;
