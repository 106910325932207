import { ChangeEvent, FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { scroller } from 'react-scroll';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ScaleFade,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useUser } from '@/hooks';
import { Comment, Question, QuestionsProps, Submission } from '@/types/pages/questions';
import { LoadingState } from '@/types';
import {
  AccessGroup,
  Difficulty,
  MAP_QUESTION_CATEGORY_TO_TEXT,
  QuestionCategory,
  QuestionStatus,
  QuestionStatusFilter,
  Routes,
} from '@/constants';
import { CommentService, Logger, QuestionService, SubmissionService } from '@/services';
import { BonusQuestions, CrossIcon, LoadingToast, LoginModal, ReferralSection, SearchIcon } from '@/components/common';
import {
  MenuFilterCategory,
  MenuFilterDifficulty,
  MenuFilterStatus,
  ProgressSection,
  QuestionsHeading,
  QuestionsTable,
  TagsSection,
} from '@/components/pages/questions';
import { intersection, MAP_SECTION_ANCHOR_TO_TEXT, SectionAnchor } from '@/utils';
import { RequestOptions } from '@/types/api';
import { useQuestionsStore } from '@/stores';
import { QuestionListLayout } from '@/components/layout';

interface QuestionsListProps extends QuestionsProps {
  questionsCategory?: QuestionCategory;
  questionOptions?: RequestOptions;
  shouldShowTags?: boolean;
  isCommonQuestionsPage?: boolean;
  isGeneralAssemblyMvp?: boolean;
}

const BONUS_QUESTION_FIELDS = ['id', 'slug', 'title', 'difficulty', 'company', 'sort', 'category', 'access_groups'];

const loadingState = {
  loading: true,
  fetched: false,
};

const GetQuestionsList: FC<QuestionsListProps> = (props) => {
  const {
    questions,
    selectedFilterParams,
    questionsCategory = QuestionCategory.SQL,
    questionOptions,
    tags,
    shouldShowTags = true,
    isMobile,
    isGeneralAssemblyMvp,
    isCommonQuestionsPage,
  } = props;

  const { company, category, difficulty, status } = selectedFilterParams;
  const categoryTitle = MAP_QUESTION_CATEGORY_TO_TEXT[questionsCategory];
  const pageTitle = `${categoryTitle} Interview Questions | DataLemur`;
  const pageSeoDescription = `Practice real ${categoryTitle} Interview Questions asked by Amazon, Google, Facebook (Meta), and other FAANG companies during Data Science and Data Analyst interviews.`;
  const scrollOffset = isMobile ? -80 : -77;

  const toast = useToast();
  const { asPath, push } = useRouter();
  const { user, isAuthInitialized } = useUser();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose } = useDisclosure();
  const { setFilterParams } = useQuestionsStore();

  const sectionAnchor = asPath.includes('#') ? (asPath.split('#').at(-1) as SectionAnchor) : null;

  const [
    { fetched: areBonusQuestionsFetched, data: bonusQuestions, loading: areBonusQuestionsLoading },
    setBonusQuestionsState,
  ] = useState<LoadingState<Question[]>>({
    loading: false,
    fetched: true,
    data: [],
  });
  const [{ fetched: areSubmissionsFetched, data: submissions }, setSubmissionsState] = useState<
    LoadingState<Submission[]>
  >({
    loading: false,
    fetched: true,
    data: [],
  });
  const [{ fetched: areCommentsFetched, data: comments }, setCommentsState] = useState<LoadingState<Comment[]>>({
    loading: false,
    fetched: true,
    data: [],
  });

  const [filterInputValue, setFilterInputValue] = useState<string>(() => company || '');
  const [filterDifficulty, setFilterDifficulty] = useState<Difficulty | null>(() => difficulty || null);
  const [filterCategory, setFilterCategory] = useState<QuestionCategory | null>(() => category || null);
  const [filterStatus, setFilterStatus] = useState<QuestionStatusFilter | null>(() => status || null);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const isResetFiltersButtonHidden = useMemo(
    () => !(filterInputValue || filterDifficulty || filterCategory || filterStatus),
    [filterCategory, filterDifficulty, filterInputValue, filterStatus],
  );
  const currentQuestions = useMemo(
    () =>
      user ? questions : questions.filter((question) => !question.accessGroups?.includes(AccessGroup.PremiumQuestions)),
    [questions, user],
  );
  const referralBonusQuestions = useMemo(
    () =>
      bonusQuestions.filter((bonusQuestion) => !bonusQuestion.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway)),
    [bonusQuestions],
  );
  const septemberGiveawayQuestions = useMemo(
    () =>
      user?.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway)
        ? bonusQuestions.filter((bonusQuestion) =>
            bonusQuestion.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway),
          )
        : [],
    [user, bonusQuestions],
  );

  const shouldDisplayBonusQuestionsSection = areBonusQuestionsFetched && Boolean(bonusQuestions.length);
  const shouldDisplayReferralQuestions = areBonusQuestionsFetched && Boolean(referralBonusQuestions.length);

  const relatedTagAndQuestionsIds = useMemo(() => {
    const init: Record<string, number[]> = {};
    tags.map((tag) => {
      const objKey = tag.id;
      init[objKey] = currentQuestions
        .filter((question) => question.tags?.some((questionTags) => questionTags.questionTagsId === tag.id))
        .map((question) => question.id);
    });
    return init;
  }, [tags, currentQuestions]);

  const tagsWithQuestionsAmount = useMemo(
    () => tags.map((tag) => ({ ...tag, questionsAmount: relatedTagAndQuestionsIds[tag.id]?.length || 0 })),
    [tags, relatedTagAndQuestionsIds],
  );

  const intersectedQuestionIds = useMemo(() => {
    const selectedQuestionIds = selectedTagIds.map((tagId) => relatedTagAndQuestionsIds[tagId]);
    return intersection(selectedQuestionIds);
  }, [selectedTagIds, relatedTagAndQuestionsIds]);

  const isLoadingSubmissions = !areSubmissionsFetched && !areCommentsFetched;
  const isProgressSectionLoading =
    (isLoadingSubmissions && !areBonusQuestionsFetched) || (!bonusQuestions.length && areBonusQuestionsLoading);
  const userId = user?.id;

  const progressBarQuestions = useMemo(
    () => (isProgressSectionLoading ? [] : [...currentQuestions, ...bonusQuestions]),
    [currentQuestions, bonusQuestions, isProgressSectionLoading],
  );

  const getQuestionFilterStatus = useCallback(
    (questionId: number): QuestionStatusFilter | null => {
      const submission = submissions.find((el) => el.questionId === questionId);

      if (!submission) return QuestionStatusFilter.NotStarted;

      switch (submission.status) {
        case QuestionStatus.Wrong:
          return QuestionStatusFilter.Error;
        case QuestionStatus.Mismatched:
          return QuestionStatusFilter.Error;
        case QuestionStatus.Solved:
          return QuestionStatusFilter.Solved;
        default:
          return null;
      }
    },
    [submissions],
  );

  const filteredQuestions: Question[] = useMemo(
    () =>
      currentQuestions.filter((question) => {
        const searchString = filterInputValue?.toLocaleLowerCase().trim() || '';
        const isSearchIncluded =
          question.title?.toLocaleLowerCase().includes(searchString) ||
          question.company?.toLocaleLowerCase().includes(searchString);
        const isSelectedTagPassed = selectedTagIds.length === 0 || intersectedQuestionIds?.includes(question.id);
        const isDifficultyFilterPassed = !filterDifficulty || question.difficulty === filterDifficulty;
        const isStatusFilterPassed = !filterStatus || getQuestionFilterStatus(question.id) === filterStatus;
        const isCategoryFilterPassed = !filterCategory || question.category === filterCategory;

        return (
          isSearchIncluded &&
          isDifficultyFilterPassed &&
          isStatusFilterPassed &&
          isSelectedTagPassed &&
          isCategoryFilterPassed
        );
      }),
    [
      currentQuestions,
      filterInputValue,
      filterDifficulty,
      filterStatus,
      filterCategory,
      getQuestionFilterStatus,
      intersectedQuestionIds,
      selectedTagIds.length,
    ],
  );

  const handleFilterInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setFilterInputValue(event.target.value);
  }, []);

  const scrollToAnchorSection = useCallback(
    () =>
      sectionAnchor
        ? scroller.scrollTo(sectionAnchor, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: scrollOffset,
          })
        : undefined,
    [scrollOffset, sectionAnchor],
  );

  const resetFilters = async () => {
    await push({}, undefined, { shallow: true });

    setFilterParams(null);
    setFilterInputValue('');
    setFilterCategory(null);
    setFilterDifficulty(null);
    setFilterStatus(null);
  };

  useEffect(() => {
    if (!isAuthInitialized || !userId) {
      return;
    }

    // make requests only if userId exists
    (async () => {
      setCommentsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));
      setSubmissionsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));

      try {
        const [fetchedSubmissions, fetchedComments] = await Promise.all([
          SubmissionService.getLatestSubmissionForEachQuestion(),
          CommentService.getComments<Comment>({
            fields: ['id', 'question_id'],
            filter: {
              deleted_at: { _null: true },
              user_id: { _eq: userId },
            },
          }),
        ]);

        setCommentsState({
          loading: false,
          fetched: true,
          data: fetchedComments,
        });

        setSubmissionsState({
          loading: false,
          fetched: true,
          data: fetchedSubmissions,
        });
      } catch (error) {
        Logger.error(error);

        setCommentsState({
          loading: false,
          fetched: true,
          data: [],
        });

        setSubmissionsState({
          loading: false,
          fetched: true,
          data: [],
        });
      }

      if (!user?.accessGroups || !user?.accessGroups.length) {
        return;
      }

      setBonusQuestionsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));

      try {
        const bonusUserQuestions = await QuestionService.getUserQuestions({
          ...questionOptions,
          fields: BONUS_QUESTION_FIELDS,
        });

        setBonusQuestionsState({ loading: false, fetched: true, data: bonusUserQuestions });
      } catch (error) {
        Logger.error(error);

        setBonusQuestionsState({ loading: false, fetched: true, data: [] });
      }
    })();
  }, [isAuthInitialized, questionOptions, user?.accessGroups, userId]);

  useEffect(() => {
    if (sectionAnchor && sectionAnchor !== SectionAnchor.ReferralSection && !toast.isActive(sectionAnchor)) {
      toast({
        position: 'bottom-right',
        isClosable: true,
        variant: 'top-accent',
        render(): ReactNode {
          return <LoadingToast title={MAP_SECTION_ANCHOR_TO_TEXT[sectionAnchor]} />;
        },
      });
    }

    if (sectionAnchor && shouldDisplayReferralQuestions) {
      push(Routes.Questions, undefined, { shallow: true });
      scrollToAnchorSection();
      toast.closeAll();
    }
  }, [shouldDisplayReferralQuestions, sectionAnchor, push, scrollToAnchorSection, toast]);

  return (
    <QuestionListLayout
      tabTitle={pageTitle}
      tabDescription={pageSeoDescription}
      isGeneralAssemblyMvp={isGeneralAssemblyMvp}
    >
      <Container maxWidth="100%" py="8px" display="flex">
        <Box w="100%">
          <LoginModal isModalOpen={isOpenModal} onClose={onClose} />

          <QuestionsHeading
            questionsCategory={questionsCategory}
            isCommonQuestionsPage={isCommonQuestionsPage}
            isGeneralAssemblyMvp={isGeneralAssemblyMvp}
            isMobile={isMobile}
          />

          <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} gap="8px" mt="20px" mb="8px">
            <InputGroup maxW={{ base: '100%', md: '480px' }}>
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>

              <Input
                placeholder="Search questions/companies"
                value={filterInputValue}
                onChange={handleFilterInputChange}
              />

              <InputRightElement hidden={!filterInputValue}>
                <CrossIcon cursor="pointer" color="black.200" onClick={() => setFilterInputValue('')} />
              </InputRightElement>
            </InputGroup>

            <Flex width={{ base: '100%', md: 'auto' }} gap="8px">
              {isCommonQuestionsPage && (
                <MenuFilterCategory filterCategory={filterCategory} setFilterCategory={setFilterCategory} />
              )}
              <MenuFilterDifficulty filterDifficulty={filterDifficulty} setFilterDifficulty={setFilterDifficulty} />
            </Flex>

            <Flex width={{ base: '100%', md: 'auto' }} flexDir={{ base: 'column', md: 'row' }} gap="8px">
              {user && (
                <MenuFilterStatus
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  isOpen={Boolean(user) && undefined}
                  onOpen={user ? undefined : onOpenModal}
                  isDisabled={isLoadingSubmissions}
                />
              )}

              <Button
                variant="outlineCustom"
                onClick={resetFilters}
                minWidth={{ base: '100%', md: '120px' }}
                hidden={isResetFiltersButtonHidden}
              >
                Clear filters
              </Button>
            </Flex>
          </Flex>

          <QuestionsTable
            questions={filteredQuestions}
            comments={comments}
            submissions={submissions}
            isLoadingSubmissions={isLoadingSubmissions}
            isCommonQuestionsPage={isCommonQuestionsPage}
            isGeneralAssemblyMvp={isGeneralAssemblyMvp}
          />
          {user && !isGeneralAssemblyMvp && (
            <ScaleFade in={Boolean(user)}>
              <Box mt="20px">
                <ReferralSection shouldScrollBelowToSeeQuestions={true} />
                <ScaleFade in={shouldDisplayBonusQuestionsSection}>
                  {shouldDisplayReferralQuestions ? (
                    <BonusQuestions
                      questions={referralBonusQuestions}
                      comments={comments}
                      submissions={submissions}
                      isLoadingSubmissions={isLoadingSubmissions}
                      bonusQuestionsId={SectionAnchor.BonusQuestions}
                    />
                  ) : null}
                  {septemberGiveawayQuestions.length ? (
                    <BonusQuestions
                      title="SQL September Questions"
                      questions={septemberGiveawayQuestions}
                      comments={comments}
                      submissions={submissions}
                      isSubtitleVisible
                    />
                  ) : null}
                </ScaleFade>
              </Box>
            </ScaleFade>
          )}
        </Box>
        {!isMobile && (
          <Flex display="column" ml="24px">
            <ProgressSection
              questions={progressBarQuestions}
              submissions={submissions}
              comments={comments}
              isDataLoading={isProgressSectionLoading}
            />
            {shouldShowTags && (
              <TagsSection
                tags={tagsWithQuestionsAmount}
                selectedTagIds={selectedTagIds}
                setSelectedTagIds={setSelectedTagIds}
              />
            )}
          </Flex>
        )}
      </Container>
    </QuestionListLayout>
  );
};

export default memo(GetQuestionsList);
