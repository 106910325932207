import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MAP_RUNTIMES_TO_TEXT, SupportedRuntime } from '@/constants';

interface MenuQuestionRuntimesProps {
  supportedRuntimes?: SupportedRuntime[];
  currentRuntime?: SupportedRuntime;
  setCurrentRuntime?: Dispatch<SetStateAction<SupportedRuntime>>;
}

export const MenuQuestionRuntimes: FC<MenuQuestionRuntimesProps> = (props) => {
  const { currentRuntime, setCurrentRuntime, supportedRuntimes } = props;

  const setRuntimeHandler = (runtime: SupportedRuntime) => {
    if (!setCurrentRuntime) {
      return;
    }

    setCurrentRuntime(runtime);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            variant="wrapper"
            borderRadius="8px"
            border="1px solid"
            borderColor="gray.300"
            color="black.200"
            // bg="gray.200"
            // mr={{ base: '0', sm: '8px' }}
            // mt={{ base: '8px', sm: '0' }}
            // minW={{ base: '100%', sm: 'auto' }}
            // p="6px"
            // w="160px"
            // _hover={{ bg: 'gray.300', _active: { bg: 'gray.400' } }}
            isActive={isOpen}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {currentRuntime && MAP_RUNTIMES_TO_TEXT[currentRuntime]}
          </MenuButton>
          <MenuList zIndex={10}>
            {supportedRuntimes?.map((runtime) => {
              return (
                <MenuItem
                  as={Button}
                  variant="wrapper"
                  key={runtime}
                  onClick={() => setRuntimeHandler(runtime)}
                  //ToDo: fix after ship to prod
                  // isDisabled={!isPostgresRuntime}
                  // backgroundColor={runtime === currentRuntime ? 'gray.300' : undefined}
                >
                  {MAP_RUNTIMES_TO_TEXT[runtime]}
                  {/*{!isPostgresRuntime && (*/}
                  {/*  <Text color="red" ml="6px" fontStyle="italic">*/}
                  {/*    Coming Soon*/}
                  {/*  </Text>*/}
                  {/*)}*/}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
