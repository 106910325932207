import React, { FC } from 'react';
import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { QueryTable } from '@/types/models';
import { EmptyQueryTable } from '@/components/pages/question';
import { formatColumnTitle, processValue } from './helpers';

export const ResultQueryTable: FC<QueryTable> = (props) => {
  const { title, table = false } = props;

  if (!table || !table.length) {
    return <EmptyQueryTable title={title} />;
  }

  const keys = Object.keys(table[0]);

  return (
    <Flex flexDirection="column" gap="4px" overflow="auto">
      {title && (
        <Text ml="4px" mt="16px" fontWeight="500">
          {title}
        </Text>
      )}
      <TableContainer border="1px" borderColor="gray.300" mb="6px" borderRadius="6px">
        <Table variant="clean" colorScheme="tableOddRows">
          <Thead>
            <Tr>
              {keys.map((el, index) => (
                <Th textTransform="none" key={index}>
                  {formatColumnTitle(el)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {table.map((el, index) => (
              <Tr key={index}>
                {keys.map((key, index1) => (
                  <Td title={el[key]} key={index1}>
                    {processValue(el[key]) as string}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
