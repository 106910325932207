import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Flex, HStack, Stack, Text, useDisclosure, VStack, Wrap } from '@chakra-ui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Avatar,
  BinIcon,
  ChatSingleIcon,
  LoginModal,
  Markdown,
  MinusInCircleIcon,
  PencilIcon,
  PlusInCircleIcon,
} from '@/components/common';
import { mockCommentMarkdown, VoteOutcome } from '@/constants';
import { ReplyCommentInput } from '@/components/pages/question';
import { COMMENT_DATE_DEFAULT, NEW_COMMENT_MD_DEFAULT } from '@/constants/defaultValues';
import { useUser } from '@/hooks';
import { Vote } from '@/types/models';
import { Comment } from '@/types/pages/question';
import { CommentService, Logger, VotesService } from '@/services';
import { getUserName } from '@/utils';
import CommentMdEditor from '../CommentMdEditor';
import { getRelativeCommentTime, getTriangleIconColor, TriangleDirection } from './helpers';

export interface DiscussionCommentProps extends Comment {
  onCommentDelete: (commentId: string, parentId: string | null) => void;
  userVote: Vote | null;
  replyComments: Comment[];
  handleSubmitComment: (commentValue: string, parentId?: string) => void;
}

export enum Operations {
  WatchOperation,
  EditOperation,
  DeleteOperation,
  VoteOperation,
  EditProcessing,
  DeleteProcessing,
  VoteProcessing,
  ReplyProcessing,
}

const DiscussionComment: FC<DiscussionCommentProps> = ({
  id: commentId,
  userId,
  user: commentUser,
  body,
  parentId,
  createdAt,
  upvotes,
  downvotes,
  userVote,
  onCommentDelete,
  replyComments,
  handleSubmitComment,
}) => {
  const { user } = useUser();
  const userName = getUserName(commentUser);
  const repliesForDisplay = useMemo(
    () => [...replyComments].sort((a, b) => b.upvotes - b.downvotes - (a.upvotes - a.downvotes)),
    [replyComments],
  );
  const [isRepliesCollapsed, setIsRepliesCollapsed] = useState(false);
  const [curVotes, setCurVotes] = useState<number>(() => upvotes - downvotes);
  const [curVoteStatus, setCurrentVoteStatus] = useState<VoteOutcome | null>(userVote?.outcome || null);
  const [currentOperation, setCurrentOperation] = useState<Operations>(Operations.WatchOperation);
  //used to show in current-published comment field
  const [currentComment, setCurrentComment] = useState<string>(body || mockCommentMarkdown);
  //used to keep tracking new comment value in editor
  const [newCommentValue, setNewCommentValue] = useState<string>(body || NEW_COMMENT_MD_DEFAULT);
  const [currentUserVote, setCurrentUserVote] = useState<Vote | null>(userVote);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isCommentCreator = user?.id === userId;

  const defaultReplyValue = `**@${userName}** `;
  const replyValueCacheKey = `reply-input-${commentId}`;
  const [showReplyInput, setShowReplyInput] = useState(() => Boolean(localStorage.getItem(replyValueCacheKey)));
  const relativeCommentTime = useMemo<string>(
    () => getRelativeCommentTime(createdAt ? createdAt : COMMENT_DATE_DEFAULT.toDateString()),
    [createdAt],
  );

  const handleDeleteClick = useCallback(async (): Promise<void> => {
    //Prevent multi-clicking
    if (currentOperation !== Operations.WatchOperation) {
      return;
    }
    setCurrentOperation(Operations.DeleteProcessing);
    try {
      await CommentService.deleteComment({ commentId });
      onCommentDelete(commentId, parentId);
    } catch (error) {
      Logger.error(error);
      setCurrentOperation(Operations.WatchOperation);
    }
  }, [onCommentDelete, commentId, parentId, currentOperation]);

  const handleEditSubmit = useCallback(async (): Promise<void> => {
    //Prevent multi-clicking
    if (currentOperation !== Operations.EditOperation) {
      return;
    }

    setCurrentOperation(Operations.EditProcessing);

    try {
      await CommentService.updateComment({ commentId, newCommentValue });

      setCurrentOperation(Operations.WatchOperation);
      setNewCommentValue(newCommentValue);
      setCurrentComment(newCommentValue);
    } catch (error) {
      Logger.error(error);
      setCurrentOperation(Operations.EditOperation);
    }
  }, [commentId, newCommentValue, currentOperation]);

  const voteUp = useCallback(async (): Promise<void> => {
    if (!user) {
      onOpen();
      return;
    }
    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.VoteProcessing);

      try {
        //up: -1 -> 1
        if (curVoteStatus === VoteOutcome.Downvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 2);
          //unup: 1 -> 0
        } else if (curVoteStatus === VoteOutcome.Upvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(null);
          setCurVotes((val) => val - 1);
          //if field exists but null: 0 -> 1
        } else if (curVoteStatus === null && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 1);
          //if field doesn't exist: create(1)
        } else {
          let newVote = await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 1);
          setCurrentUserVote(newVote);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
      }
    }
  }, [curVoteStatus, currentOperation, currentUserVote, commentId, user, onOpen]);

  const voteDown = useCallback(async (): Promise<void> => {
    if (!user) {
      onOpen();
      return;
    }
    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.VoteProcessing);

      try {
        //undown: -1 -> 0
        if (curVoteStatus === VoteOutcome.Downvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(null);
          setCurVotes((val) => val + 1);
          //down: 1 -> -1
        } else if (curVoteStatus === VoteOutcome.Upvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 2);
          //if field exists but null: 0 -> -1
        } else if (curVoteStatus === null && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 1);
          //if field doesn't exist: create(-1)
        } else {
          let newVote = await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 1);
          setCurrentUserVote(newVote);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
      }
    }
  }, [curVoteStatus, currentOperation, commentId, currentUserVote, user, onOpen]);

  const handleEditClick = useCallback((): void => {
    if (currentOperation === Operations.EditOperation) {
      setCurrentOperation(Operations.WatchOperation);
      setNewCommentValue(body ? body : mockCommentMarkdown);
    }

    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.EditOperation);
    }
  }, [body, currentOperation]);

  const handleCommentEditorChange = useCallback((newVal: string | undefined): void => {
    setNewCommentValue(newVal || '');
  }, []);

  const clearReplyCache = useCallback(() => {
    localStorage.removeItem(replyValueCacheKey);
  }, [replyValueCacheKey]);

  const handleReply = () => {
    if (currentOperation === Operations.ReplyProcessing) {
      return;
    }
    if (showReplyInput) {
      clearReplyCache();
    }

    setShowReplyInput((prevState) => !prevState);
  };

  const handleReplySubmit = useCallback(
    async (replyCommentValue: string): Promise<void> => {
      setCurrentOperation(Operations.ReplyProcessing);
      try {
        await handleSubmitComment(replyCommentValue, commentId);
        clearReplyCache();
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
        setIsRepliesCollapsed(true);
        setShowReplyInput(false);
      }
    },
    [commentId, handleSubmitComment, clearReplyCache],
  );

  useEffect(() => {
    setCurrentVoteStatus(userVote?.outcome || null);
    setCurrentUserVote(userVote);
  }, [userVote]);

  const handleCollapseReplies = () =>
    repliesForDisplay.length ? setIsRepliesCollapsed((prevState) => !prevState) : undefined;

  return (
    <>
      <Flex direction="column" width="100%">
        <LoginModal isModalOpen={isOpen} onClose={onClose} />

        <Flex alignItems="center" gap="6px">
          <Avatar avatarId={commentUser.avatar} userName={userName} size="xs" mt="3px" />
          <HStack gap="8px">
            <Text fontSize="16px" fontWeight={600}>
              {userName}
            </Text>

            <Text color="black.200" fontSize="12px" fontWeight={500} mt="2px">
              {relativeCommentTime}
            </Text>
          </HStack>
        </Flex>

        <Wrap marginTop="6px">
          <Markdown>{currentComment}</Markdown>
        </Wrap>
        <Flex alignItems="center" flexWrap="wrap" gap="1px">
          {/* votes */}
          <Flex alignItems="center" gap="1px">
            <HStack gap="2px">
              <Button
                variant="wrapper"
                padding="10px 6px"
                onClick={voteUp}
                isDisabled={![Operations.WatchOperation, Operations.EditOperation].includes(currentOperation)}
                color={getTriangleIconColor(curVoteStatus, TriangleDirection.Up)}
              >
                <ArrowUpIcon color="inherit" strokeWidth={curVoteStatus === VoteOutcome.Upvote ? 2.5 : 1.5} />
              </Button>

              <Text fontWeight={600}>{curVotes}</Text>

              <Button
                variant="wrapper"
                padding="10px 6px"
                onClick={voteDown}
                isDisabled={![Operations.WatchOperation, Operations.EditOperation].includes(currentOperation)}
                color={getTriangleIconColor(curVoteStatus, TriangleDirection.Down)}
              >
                <ArrowDownIcon color="inherit" strokeWidth={curVoteStatus === VoteOutcome.Downvote ? 2.5 : 1.5} />
              </Button>
            </HStack>

            {!parentId && (
              <Button
                variant="wrapper"
                color="black.500"
                onClick={handleReply}
                isDisabled={currentOperation === Operations.ReplyProcessing}
                leftIcon={<ChatSingleIcon />}
              >
                <Text fontWeight={600}>{showReplyInput ? 'Discard reply' : 'Reply'}</Text>
              </Button>
            )}
          </Flex>

          {isCommentCreator && (
            <Flex gap="1px">
              <Button
                variant="wrapper"
                color="black.500"
                onClick={handleEditClick}
                isDisabled={![Operations.WatchOperation, Operations.EditOperation].includes(currentOperation)}
                leftIcon={<PencilIcon />}
              >
                {currentOperation === Operations.EditOperation || currentOperation === Operations.EditProcessing
                  ? 'Discard changes'
                  : 'Edit'}
              </Button>
              {![Operations.EditOperation, Operations.EditProcessing].includes(currentOperation) && (
                <Button
                  variant="wrapper"
                  color="red.850"
                  onClick={handleDeleteClick}
                  isLoading={currentOperation === Operations.DeleteProcessing}
                  isDisabled={currentOperation !== Operations.WatchOperation}
                  leftIcon={<BinIcon />}
                  loadingText="Deleting..."
                  _hover={{ bg: 'gray.200', color: 'red.850' }}
                >
                  Delete
                </Button>
              )}

              {!parentId && (
                <Button
                  variant="wrapper"
                  color="black.200"
                  onClick={handleCollapseReplies}
                  leftIcon={isRepliesCollapsed ? <MinusInCircleIcon /> : <PlusInCircleIcon />}
                >
                  {isRepliesCollapsed ? 'Hide' : repliesForDisplay.length} replies
                </Button>
              )}
            </Flex>
          )}
        </Flex>

        {(currentOperation === Operations.EditOperation || currentOperation === Operations.EditProcessing) && (
          <CommentMdEditor
            mdValue={newCommentValue}
            onChange={handleCommentEditorChange}
            onSubmit={handleEditSubmit}
            onCancel={handleEditClick}
            isLoading={currentOperation === Operations.EditProcessing}
            styleProps={{
              marginTop: '16px',
              direction: 'column',
              alignItems: 'flex-start',
              gap: '10px',
            }}
          />
        )}
      </Flex>

      {showReplyInput && (
        <ReplyCommentInput
          defaultReplyValue={defaultReplyValue}
          handleReplySubmit={handleReplySubmit}
          replyValueCacheKey={replyValueCacheKey}
          isLoading={currentOperation === Operations.ReplyProcessing}
          handleCancel={handleReply}
        />
      )}
      {!!repliesForDisplay.length && (
        <Collapse in={isRepliesCollapsed}>
          <Stack direction="row" height="100%" ml="20px" gap="20px">
            <Flex width="2px" backgroundColor="gray.300" borderRadius="2px" />

            <VStack alignItems="start" width="100%" gap="20px">
              {repliesForDisplay.map((reply) => (
                <DiscussionComment
                  key={reply.id}
                  onCommentDelete={onCommentDelete}
                  userVote={userVote}
                  replyComments={[]}
                  handleSubmitComment={handleSubmitComment}
                  {...reply}
                />
              ))}
            </VStack>
          </Stack>
        </Collapse>
      )}
    </>
  );
};

export default DiscussionComment;
