import { FC, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffectOnce, useEffectOnceWhen, useUser } from '@/hooks';
import { checkIsPremiumUser, getUserName } from '@/utils';
import { GTMEvent, Routes, SIGN_IN_REDIRECT_PATH_KEY } from '@/constants';
import { AppLink, Avatar, LogoIcon, ShareIcon, ShareModal } from '@/components/common';
import { SignInButton, SignOutButton } from '@clerk/nextjs';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';
import { Question as BaseQuestion } from '@/types/models';
import { QuestionNavigation } from '@/components/pages/question';
import { BurgerMenu, MenuIcon } from '.';

export interface HeaderProps extends Partial<Pick<BaseQuestion, 'id' | 'company' | 'category'>> {
  isGeneralAssemblyMvp?: boolean;
  isQuestionPage?: boolean;
}

export interface HeaderNavItem {
  title: string;
  path: string;
}

const NAV_ITEMS: HeaderNavItem[] = [
  { title: 'Practice Interview Questions', path: Routes.Questions },
  { title: 'Learn SQL', path: Routes.SQLTutorial },
  { title: 'Get 1:1 Coaching', path: Routes.Pricing },
];

export const Header: FC<HeaderProps> = (props) => {
  const { isGeneralAssemblyMvp, isQuestionPage, company, category, id: questionId } = props;

  const { isOpen: isMenuOpen, onToggle } = useDisclosure();
  const { isOpen: isShareModalOpen, onToggle: onShareModalToggle } = useDisclosure();
  const [isLargerThan980] = useMediaQuery(`(min-width: 890px)`, {
    ssr: true,
    fallback: false,
  });

  const [isLargerThan1040] = useMediaQuery(`(min-width: 1040px)`, {
    ssr: true,
    fallback: false,
  });

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOutsideClick, setIsOsOutsideClick] = useState<boolean>(false);
  const { asPath } = useRouter();

  const { user, isAuthInitialized, isLoading, logout, login } = useUser();
  const userName = user ? getUserName(user) : '';

  const AUTHORIZED_NAV_ITEMS = user ? NAV_ITEMS : NAV_ITEMS.filter(({ path }) => path !== Routes.Pricing);

  const handleLogout = () => {
    logout();
  };

  useOutsideClick({
    enabled: isMenuOpen,
    ref: menuRef,
    handler: () => {
      setIsOsOutsideClick(true);
      onToggle();
    },
  });

  useEffect(() => {
    if (isMenuOpen && menuRef.current) {
      disableBodyScroll(menuRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffectOnce(() => {
    localStorage.removeItem(SIGN_IN_REDIRECT_PATH_KEY);

    if (user) {
      return;
    }

    login();

    return () => {
      if (isMenuOpen) {
        onToggle();
      }
    };
  });

  const handleEsc = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape' && isMenuOpen) {
        onToggle();
      }
    },
    [isMenuOpen, onToggle],
  );

  const handleMenuIconClick = useCallback(() => {
    if (isOutsideClick) return setIsOsOutsideClick(false);

    onToggle();
  }, [isOutsideClick, onToggle]);

  useEffectOnceWhen(() => {
    // Condition to avoid TS type errors
    if (!user) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        user_id: user.id,
        event: GTMEvent.UserDetails,
        login_status: 'true',
        customer_type: checkIsPremiumUser(user) ? 'Paying' : 'Free',
      },
    });
  }, user && asPath);

  return (
    <Box zIndex={10} h="56px" position="fixed" top={0} right={0} left={0} bg="gray.100" px="4">
      <ShareModal isModalOpen={isShareModalOpen} onClose={onShareModalToggle} company={company} category={category} />

      <Flex
        position="relative"
        h="14"
        gap="30px"
        alignItems="center"
        justifyContent="space-between"
        onKeyDown={handleEsc}
        zIndex={100}
      >
        <Center gap="29px" flexShrink={0}>
          <AppLink href={Routes.Home} display="flex" mb="4px">
            {isGeneralAssemblyMvp ? (
              <Image src="/general_assembly_logo.png" width={290} height={32} alt="logo" />
            ) : (
              <LogoIcon width="161px" height="32px" />
            )}
          </AppLink>

          {isLargerThan980 && !isQuestionPage && (
            <Flex as="nav">
              {AUTHORIZED_NAV_ITEMS.map(({ title, path }) => (
                <Button
                  key={title}
                  as={AppLink}
                  href={path}
                  variant="wrapper"
                  fontFamily="body"
                  fontSize="18px"
                  color="black.500"
                  _hover={{ textDecoration: 'none', bg: 'gray.200' }}
                >
                  {title}
                </Button>
              ))}
            </Flex>
          )}
          {isLargerThan980 && isQuestionPage && <QuestionNavigation id={questionId!} />}
        </Center>

        <Flex gap="8px" alignItems="center">
          {isLargerThan980 && isAuthInitialized && user && (
            <Menu>
              <MenuButton
                as={Button}
                rounded="full"
                variant="link"
                cursor="pointer"
                minW="0"
                _hover={{ textDecoration: 'none' }}
              >
                <Center gap="6px">
                  <Avatar variant="outline" size="xs" mt="3px" avatarId={user.avatar} userName={userName} />

                  <Text fontWeight={600} color="black.500" textAlign="right" fontFamily="body" wordBreak="break-word">
                    {userName}
                  </Text>
                </Center>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <AppLink href={Routes.ProfilePerformance} w="100%" _hover={{ textDecor: 'none' }}>
                    Your Performance
                  </AppLink>
                </MenuItem>
                <MenuItem>
                  <AppLink href={Routes.AccountSettings} w="100%" _hover={{ textDecor: 'none' }}>
                    Account Settings
                  </AppLink>
                </MenuItem>
                <MenuItem>
                  <AppLink href={Routes.Premium} w="100%" _hover={{ textDecor: 'none' }}>
                    Premium
                  </AppLink>
                </MenuItem>
                <SignOutButton redirectUrl={asPath}>
                  <MenuItem onClick={!isLoading ? handleLogout : undefined}>Log out</MenuItem>
                </SignOutButton>
              </MenuList>
            </Menu>
          )}

          <Button
            display={{ base: 'none', lg: 'flex' }}
            variant="wrapper"
            color="black.500"
            fontWeight={600}
            maxH="32px"
            fontFamily="body"
            leftIcon={<ShareIcon />}
            onClick={onShareModalToggle}
          >
            Share
          </Button>

          {user && isLargerThan1040 && (
            <Button
              as={AppLink}
              href={Routes.Premium}
              maxH="32px"
              variant="primaryCustom"
              colorScheme="red"
              isDisabled={isLoading}
              _hover={{ textDecoration: 'none' }}
              onClick={() => handleSaveRedirectUrl(asPath)}
            >
              Premium
            </Button>
          )}

          {isLargerThan980 && isAuthInitialized && !user && (
            <Flex alignItems="center">
              <SignInButton>
                <Button
                  maxH="32px"
                  variant="primaryCustom"
                  colorScheme="red"
                  isDisabled={isLoading}
                  onClick={() => handleSaveRedirectUrl(asPath)}
                >
                  Sign In
                </Button>
              </SignInButton>
            </Flex>
          )}
        </Flex>

        {!isLargerThan980 && <MenuIcon isOpen={isMenuOpen} handleClick={handleMenuIconClick} />}
      </Flex>

      <BurgerMenu menuItems={AUTHORIZED_NAV_ITEMS} isOpen={isMenuOpen} forwardedRef={menuRef} />
    </Box>
  );
};
